export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to Clipboard"])},
        "copySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied to Clipboard successfully!"])},
        "copyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to copy to Clipboard."])}
      },
      "zh": {
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制到剪贴板"])},
        "copySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功复制到剪贴板！"])},
        "copyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未能复制到剪贴板！"])}
      }
    }
  })
}
