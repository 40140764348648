export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "clear1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is going to clear"])},
        "clear2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", do you confirm to continue?"])}
      },
      "zh": {
        "clear1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作将清除"])},
        "clear2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["的相关设置，是否继续？"])}
      }
    }
  })
}
